import "./AdminFiles.css";
import { useNavigate } from "react-router-dom";
import Button from "../../General/Button";
import Header from "../../Header/Header";

export default function ExpensesFile() {
  const navigate = useNavigate();
  return (
    <>
      <Header goBack />
      <main className="files-main">
        <Button text="Gastos" onClick={() => navigate("/admin/expense")} />
        <Button
          text="Ver Gastos"
          onClick={() => navigate("/admin/showExpenses")}
        />
        <Button
          text="Agregar Recibos"
          onClick={() => navigate("/admin/bills")}
        />
      </main>
    </>
  );
}
